import React from "react"
import Layout from "../components/layout";


const Post = () => (
    <Layout>
        <div className="post">
            Estou no post
        </div>
    </Layout>
)

export default Post
